/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import LazyLoad from 'react-lazyload';

import getSxAttributes from 'helpers/getSxAttributes';
import Media from './Media';
import Static from './Static';

const isStatic = (src) =>
  RegExp(/^public\/images-next\//).test(src) || RegExp(/^data:image\/svg\+xml/).test(src);

export class Image extends Component {
  state = {
    hideImage: false,
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.src !== this.props.src) {
      this.setState({ hideImage: true });
    }
  };

  onImageLoad = () => {
    this.setState({ hideImage: false });
  };

  pinterestParams = () => {
    const { pinterestDescription, alt } = this.props;

    const params = {};

    if (pinterestDescription) params['data-pin-description'] = pinterestDescription;
    if (!params['data-pin-description'] && alt) params['data-pin-description'] = alt;

    return params;
  };

  renderContent() {
    const {
      alt,
      className,
      height,
      lazyLoad,
      performanceMark,
      quality,
      src,
      srcMobile,
      sx,
      sxPicture,
    } = this.props;

    const childProps = {
      alt,
      className: className,
      sx: (theme) => ({
        transition: '0.5s filter linear',
        filter: !this.state.hideImage ? 'none' : 'blur(50px)',
        visibility: this.state.hideImage ? 'hidden' : 'initial',
        ...getSxAttributes(theme, sx),
      }),
      sxPicture,
      src,
      srcMobile,
    };

    const isStaticMedia = isStatic(src);

    const content = isStaticMedia ? (
      <Static {...childProps} performanceMark={performanceMark} onLoad={this.onImageLoad} />
    ) : (
      <Media {...childProps} quality={quality} onLoad={this.onImageLoad} />
    );

    if (!lazyLoad) return content;

    return (
      <LazyLoad
        offset={100}
        height={height}
        placeholder={
          <Box
            sx={(theme) => ({
              width: '100%',
              ...getSxAttributes(theme, sx),
            })}
          />
        }
        once
      >
        {content}
      </LazyLoad>
    );
  }

  render() {
    const { src, showPinterestButton, sxContainer } = this.props;

    return (
      <Box
        sx={(theme) => ({
          position: 'relative',
          display: 'inherit',
          backgroundColor: !this.state.hideImage ? 'transparent' : theme.palette.grey[200],
          ...getSxAttributes(theme, sxContainer),
        })}
        data-testid="image-wrapper"
      >
        <Box display="flex" width="100%">
          {this.renderContent()}
        </Box>

        {showPinterestButton && (
          <Box
            sx={{
              bottom: '5px',
              left: '5px',
              position: 'absolute',
              maxWidth: '100%',
              overflow: 'hidden',
              textAlign: 'left',
              filter: this.state.hideImage ? 'blur(50px)' : 'initial',
              visibility: this.state.hideImage ? 'hidden' : 'initial',
            }}
            className={'test-pinterest'}
          >
            <a // eslint-disable-line
              aria-label="pinterest-button"
              {...this.pinterestParams()}
              data-pin-do="buttonBookmark"
              data-pin-tall="true"
              data-pin-media={src}
              href="https://www.pinterest.com/pin/create/button"
            />
          </Box>
        )}
      </Box>
    );
  }
}

Image.propTypes = {
  alt: PropTypes.string.isRequired,
  sxContainer: PropTypes.shape({}),
  sxPicture: PropTypes.shape({}),
  className: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  lazyLoad: PropTypes.bool,
  performanceMark: PropTypes.string,
  pinterestDescription: PropTypes.string,
  quality: PropTypes.number,
  showPinterestButton: PropTypes.bool,
  src: PropTypes.string.isRequired,
  srcMobile: PropTypes.string,
};

Image.defaultProps = {
  className: '',
  sxContainer: {},
  sxPicture: {},
  height: '100%',
  lazyLoad: true,
  performanceMark: '',
  pinterestDescription: '',
  quality: 50,
  showPinterestButton: false,
  srcMobile: null,
};

export default Image;

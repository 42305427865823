import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@tkww/the-bash-frontend';
import { styled } from '@mui/material/styles';

const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.dark,
  fontFamily: theme.fonts.semibold,
  fontSize: 12,
  lineHeight: '12px',
  margin: 0,
  padding: '6px 8px',
  backgroundColor: theme.palette.green[100],
  letterSpacing: 0.2,
  textDecoration: 'none',
  '&:hover': {
    backgroundColor: theme.palette.green[200],
    textDecoration: 'none',
  },
  '&:hover, &:focus, &:active': {
    color: theme.palette.text.dark,
  },
  '&:focus, &:active': {
    backgroundColor: theme.palette.green[400],
  },
}));

const Tag = ({ name, path, onClick, ...other }) => (
  <StyledButton data-testid="tag" {...other} path={path} onClick={onClick}>
    {name}
  </StyledButton>
);

Tag.propTypes = {
  name: PropTypes.string.isRequired,
  path: PropTypes.string,
  onClick: PropTypes.func,
};

Tag.defaultProps = {
  path: '',
  onClick: () => {},
};

export default Tag;

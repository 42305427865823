/* eslint-disable jsx-a11y/alt-text */

import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

import formatMediaApiUrl from 'helpers/formatMediaApiUrl';
import getSxAttributes from 'helpers/getSxAttributes';
import sxPropType from 'propTypes/sx';

export const Media = ({ alt, className, quality, src, srcMobile, onLoad, sx, sxPicture }) => {
  const mediaApiUrl = formatMediaApiUrl(src, quality, '');
  let mediaApiUrlMobile = null;
  if (srcMobile !== null) {
    mediaApiUrlMobile = formatMediaApiUrl(srcMobile, quality, '');
  }

  const theme = useTheme();
  const mobileGalleryImageMediaQuery = `(max-width: ${theme.breakpoints.values.sm - 0.1}px)`;
  const desktopGalleryImageMediaQuery = `(min-width: ${theme.breakpoints.values.sm}px)`;

  return (
    <Box
      component="picture"
      data-testid="picture"
      sx={(theme) => ({
        width: '100%',
        fontSize: 0,
        lineHeight: 0,
        ...getSxAttributes(theme, sxPicture),
      })}
    >
      {mediaApiUrlMobile && (
        <source srcSet={mediaApiUrlMobile} media={mobileGalleryImageMediaQuery} />
      )}
      <source data-testid="source" srcSet={mediaApiUrl} media={desktopGalleryImageMediaQuery} />
      <Box
        component="img"
        src={mediaApiUrl}
        alt={alt}
        className={className}
        sx={sx}
        onLoad={onLoad}
      />
    </Box>
  );
};

Media.propTypes = {
  alt: PropTypes.string.isRequired,
  sx: sxPropType,
  sxPicture: sxPropType,
  className: PropTypes.string.isRequired,
  onLoad: PropTypes.func,
  quality: PropTypes.number,
  src: PropTypes.string.isRequired,
  srcMobile: PropTypes.string,
};

Media.defaultProps = {
  sx: {},
  sxPicture: {},
  onLoad: () => {},
  quality: null,
  srcMobile: null,
};

export default Media;

/* eslint-disable jsx-a11y/alt-text */

import React from 'react';
import PropTypes from 'prop-types';

import PerformanceMark from '../PerformanceMark';
import { Box } from '@mui/material';
import sxPropType from 'propTypes/sx';

const isSvg = (src) => RegExp(/\.svg$/).test(src) || RegExp(/^data:image\/svg\+xml/).test(src);

const Static = ({ alt, className, performanceMark, src, onLoad, sx }) => {
  if (isSvg(src)) {
    return <img src={src} alt={alt} className={className} onLoad={onLoad} />;
  }

  const imgProps = {
    alt,
    className,
    sx,
    src: `${src}.jpg`,
  };

  if (performanceMark && typeof window !== 'undefined' && window.performance) {
    imgProps.onLoad = () => {
      onLoad();
      performance.clearMarks(performanceMark);
      performance.mark(performanceMark);
    };
  }

  return (
    <>
      <picture>
        <source type="image/webp" srcSet={`${src}.webp`} />
        <Box component="img" {...imgProps} />
        {performanceMark && <PerformanceMark name={performanceMark} />}
      </picture>
    </>
  );
};

Static.propTypes = {
  alt: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  onLoad: PropTypes.func,
  performanceMark: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  sx: sxPropType,
};

Static.defaultProps = {
  onLoad: () => {},
  sx: {},
};

export default Static;

import React from 'react';
import PropTypes from 'prop-types';

import Script from './Script';

const PerformanceMark = ({ name }) => (
  <Script javascript={`if (window.performance) window.performance.mark("${name}");`} />
);
PerformanceMark.displayName = 'PerformanceMark';

PerformanceMark.propTypes = {
  name: PropTypes.string.isRequired,
};

export default PerformanceMark;

const mediaApiRegex = new RegExp('media-api');

const formatMediaApiUrl = (src, quality, fileFormat) => {
  if (!mediaApiRegex.test(src)) return src;

  const fileFormatRegex = new RegExp(fileFormat);
  const base = fileFormatRegex.test(src) ? src : `${src}.${fileFormat}`;
  return `${base}?quality=${quality}`;
};

export default formatMediaApiUrl;

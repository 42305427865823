import PropTypes from 'prop-types';
import React from 'react';

// eslint-disable-next-line
const Script = ({ javascript }) => <script dangerouslySetInnerHTML={{ __html: javascript }} />;

Script.propTypes = {
  javascript: PropTypes.string,
};

Script.defaultProps = {
  javascript: '',
};

Script.displayName = 'Script';
export default Script;
